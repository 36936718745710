<template>
    <div class="page-carnet">
        <h1>Carnet de voyage</h1>
        <p>
        <strong class="ql-size-large">Carnet page 1</strong>
        </p>
        <p>
        <span class="ql-size-large">13 aout 1914, combats à Avricourt village frontière près de la forêt de Parroy. Le lendemain franchissement de la Frontière.&nbsp;
        </span>
        </p>
        <p>
        <span class="ql-size-large">Le 20 aout retraite de Dieuze, 21 transporté à l’hôpital de Lunéville.&nbsp;22 caserné dans la côte d’Or.</span>
        </p>
        <p>
        <span class="ql-size-large">27 départ, 28 arrivée à Bayon, 30 combats très violents près de Gerbéviller, 31 également jusqu’au 12 septembre, le 11 au soir et 12 septembre, 25 Bataillon cantonné à la Brasserie de Bière de Gerbéviller, affreux spectacle des infortunés tués du 36</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> Colonial.&nbsp;
        </span>
        </p>
        <p>
        <span class="ql-size-large\">13 septembre rentrée à Lunéville, les Boches se sont retirés à la forêt de Parroy, sommes cantonnés dans la caserne des 8
        </span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large">&nbsp; chasseurs à cheval, le 15 ou 16 départ pour Nancy. Repartis de Nancy le 20 pour Bernécourt, avons attaqué le 22 entre Bernécourt et Beaumont, le 27 blessé à la tête, avons tenu les tranchées jusqu’au 10 octobre tout en combattant, le 10 relève, embarquement à boul (?) pour&nbsp;
        </span>
        </p>
        <img class="carnet-image" src="/images/5a2d05ea-b002-4ccf-925c-c82d1c2b50f5MS.jpg" />

        <p>
        <strong class="ql-size-large">Carnet page 2</strong>
        </p>
        <p>Château-Thierry, débarquement le 11 octobre départ de Château-Thierry par étapes en passant par Compiègne, Soissons, Montdidier, aux environs de cette dernière ville une semaine de jours de manœuvres.&nbsp;Le 25 embarquement à la gare de Montdidier départ pour la Belgique, le 26 octobre débarquement à Bailleul, le jour même départ par autobus débarquement à Ypres&nbsp;Belgique.&nbsp;</p>
        <p><br></p>
        <p>Arrivée à St Julian la nuit, le 27 attaque de 2<sup>ème</sup> bataillon, commandant Varbette&nbsp;? tué, Lieutenant Bertrand tué, de la 7<sup>ème</sup> Compagnie 200 hommes hors de combats, le 28 Comandant du 1<sup>er</sup> et 3<sup>ème</sup> Bataillon tués, attaques repoussées, 29, 30, 31 attaque repoussées, le 1<sup>er</sup> novembre mon escouade est désigné pour enterrer les 48 tués de l’attaque du 27 octobre 1914. </p>
        <p>Le 2 (novembre) de 6 à 7 heures attaque de Boches repoussée avec pertes pour l’ennemi.&nbsp;Le 3 enterré dans les tranchées, éboulement par un obus, blessé aux reins, le 4 étant blessé suis resté sous le bombardement Boches, le 5 suis allé à la visite, le 6 et le 7 repos, le 8 évacué de St Julien sur Rennes, arrivée à l’hôpital de Rennes le 11, sorti le 19 pour rentrer à&nbsp;</p>

        <img class="carnet-image" src="/images/843a7426-af74-446c-b750-36a17066d48eMS.jpg" />
        <p>
        <strong class="ql-size-large">Carnet page 3</strong>
        </p>
        <p><span class="ql-size-large">l’hôpital de Mordelle le même jour.&nbsp;Sorti de l’hôpital le </span>
        <strong class="ql-size-large">30 janvier 1915</strong>
        <span class="ql-size-large"> permission jusqu’au 19 février, le 9 rentré au dépot 30 C<sup>ie</sup> du 81<sup>ème</sup></span>
        <span class="ql-size-large">&nbsp;: Départ le 26 avril de Montpellier avec le 9</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> Bataillon de départ du 81</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> vers la 33<sup>ème</sup> Compagnie, arrivé à Remiremont (Vosges) le 27 avril à 1h du soir, départ de Remiremont le 25 Mai pour Wesserling le même jour.&nbsp;Le 11 aout 1915 versé ou affecté au 5</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> Bataillon de chasseurs à pied à Moosch pour compléter les pertes du Bataillon aux attaques du Linges perte de 8 à 900 hommes, le Bataillon a été relevé le 9 aout le jour que le Président de la République a passé la revue à Wesserling.&nbsp;Le 30 aout départ de Moosch pour les tranchées au Sudelkopf, au mois de décembre départ au Hirstein, gauche de l’Hartmannswillerkopf pour quelques jours pour reconnaitre l’emplacement du secteur en vue de l’attaque de 21 décembre.</span></p>

        <img class="carnet-image" src="/images/379d1a42-aa23-4dcf-8d3f-5d9c375aae61MS.jpg" />
        <p>
        <strong class="ql-size-large">Carnet page 4</strong>
        </p>
        <p><span class="ql-size-large">Le 21 décembre, attaque de l’Hartmannswillerkopf à 9 heures du matin préparation terrible de l’artillerie de 2 heures, attaque par les chasseurs des tranchées allemandes 1300 prisonniers, relevé le 27 repos à Moosch, attaque (des tranchées que nous avions prises) par les Boches du 22 décembre jusqu’au 8 janvier. Le 8 départ de Moosch pour le camp Magenta<sup>(?)</sup>, le 27 départ pour les tranchées à la crête de l’Hartmannswillerkopf jusqu’au 22 Mars, 72 jours en lignes (en permission le 5 au 19 février) le 20 mars repos à Oderen. Le 15 avril départ à nouveau pour l’Hartmannswillerkopf mêmes emplacements jusqu’au 25 mai.&nbsp;Du 25 au 26 mai départ à Moosch, le 29 départ pour Oderen, le 30 départ pour Metzeral arrivée le 1 ou 2 juin aux tranchées du Branunkoff, le 27 juin relève par la 44</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> Infanterie, départ pour Epinal par étapes par Gérardmer. </span></p><p>
        <span class="ql-size-large">Manœuvres de division préparation aux futures attaques de la Somme.&nbsp;Le 21 juillet embarquement pour Rothois Oise, vers le 10 aout départ par étapes pour Étinehem (Somme) Le 19 aout départ d’Étinehem pour attaquer Cléry.&nbsp;</span></p>

        <img class="carnet-image" src="/images/40a3321b-c439-4dfc-bf65-6b058dd3e6c7MS.jpg" />

        <p>
        <strong class="ql-size-large">Carnet page 4 verso</strong>
        </p>
        <p><span class="ql-size-large">21-12-15 à 9h1/2 du matin. Bombardement terrible jusqu’à 2 h de l’après-midi. à 2h attaque de l’infanterie, toutes les tranchées Boches prises, le 22 petit repli de la 3</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> compagnie a résisté vaillamment dans la nuit du 22 au 23 abandon de la position à 3h du matin par ordre, le 23 bombardement terribles le 24 également, le 25 également, le 26 repos à 400 mètres des lignes toujours en alerte. Le 4 Janvier 16 à 9 heures et demi du soir, un obus est tombé à 4 mètre de nous, Garnache Henri de St Jean de Faust, Guillet Ruppin de Cette, Burnet de St Rémy Bouches du Rhône et son copain, une sœur a été tué, et 5 chasseurs blessés – dans la journée une sœur et 6 fillettes ont été blessées en sortant de l’école, un soldat tué, 2 blessés, plusieurs mulets tués et blessés à St Amarin dans la même journée. Dix chasseurs Alpins ont été tués.&nbsp;</span>
        </p>
        
        <img class="carnet-image" src="/images/9d55d63d-f8be-4775-a9c1-4e95fb9be0e6MS.jpg" />

        <p>
        <strong class="ql-size-large">Carnet page 5</strong>
        </p>
        <p>
        <span class="ql-size-large">Depuis le 20 aout ai pris part à toutes les attaques livrées par le 5</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> bataillon de chasseurs jusqu’au 5 septembre jour de la relève de la division 66</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> ai été blessé le jour même de la relève dans le village de Cléry à 15 heures, n’ai pu partir qu’à 6 heures du soir les batteries Boches bombardant toujours le village.&nbsp;(Blessure au genou gauche par éclat d’obus) Le 7 arrivée à l’hôpital de Calais jusqu’au 22 Septembre.&nbsp;Permission jusqu’au 30 Octobre.&nbsp;Le 30 septembre passé la visite à Montpellier pour prolongation, ai été hospitalisé le 3 octobre 1916.&nbsp;Hôpital No 3 Ecole Normale de garçons, sorti le 4 novembre&nbsp; rentré au no 10 cercle de la loge, sorti le 10 Mars 1917, rentré le 11 hôpital militaire Balaruc-les-Bains jusqu’au 11 mai, le 11 mais hôpital 49 jusqu’au 17 juillet&nbsp; le 17 juillet dépôt de physiothérapie jusqu’au 1 décembre 1917&nbsp; le 1 décembre hôpital 43 centre de réforme, jusqu’au 10 décembre&nbsp; le 10 départ au dépôt du 5</span>
        <sup class="ql-size-large">e</sup>
        <span class="ql-size-large"> Chasseurs à Lons-le-Saunier&nbsp; le 13 décembre rentré à l’hôpital mixte de Lons-le Saunier&nbsp;</span>
        </p>
        
        <img class="carnet-image" src="/images/86868e23-e8e9-44f8-9a69-f8bb330b74d0MS.jpg" />

        <p>
        <strong class="ql-size-large">Carnet page 6</strong>
        </p>
        <p><span class="ql-size-large">jusqu’au 16 janvier 1918&nbsp; le 16, 20 jours de convalescence jusqu’au 4 février, le 4 février rentré à l’hôpital Suburbain à Montpellier jusqu’au 9 avril.&nbsp;Le 10 départ pour Lons-le-Saunier, arrivée le 12 au dépôt&nbsp;; le 15 départ, arrivée le 16 à Rueil 63<sup>ème</sup></span>
        <span class="ql-size-large"> d’artillerie&nbsp; départ le 23&nbsp;pour la 64<sup>ème</sup> artillerie caserne Courcelles Paris&nbsp; départ le 28 pour St Cyr affecté au 2</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> groupe d’aérostiers&nbsp;1</span>
        <sup class="ql-size-large">ère</sup>
        <span class="ql-size-large"> Compagnie.&nbsp;Départ de St Cyr le 3 mai à Villiers-le-Bel, affecté définitivement à la 12</span>
        <sup class="ql-size-large">ème</sup>
        <span class="ql-size-large"> Section d’Aérostation.&nbsp;Défense contre avion jusqu’au 6 janvier 1919.&nbsp;Le 6 janvier envoyé à paris en subsistance au 19 train Secrétaire la démobilisation.&nbsp;</span></p>
    
        <img class="carnet-image" src="/images/41cb1ff5-0ad5-4223-8b02-fe3cca174cc0MS.jpg" />
    </div>
</template>

<script>
export default {
  name: 'Carnet',
};
</script>

<style lang="scss" scoped>
.page-carnet{
  font-size: 1.25em;
  .carnet-image {
    width: 100%;
    height: auto;
  }
}
</style>
