/* eslint-disable no-lonely-if */
import { MaxEquation } from 'three';
import { TextureLoader } from 'three';
import { Color } from 'three';
import { Mesh, PlaneBufferGeometry, MeshStandardMaterial } from 'three';

export default class CardImage {
  constructor(index, id, options, scene, updateCallback) {
    this.INDEX = index;
    this.ID = id;
    this.OPTIONS = options;
    this.LOW_RES_URL = options.lowResUrl;
    this.HIGH_RES_URL = options.highResUrl;
    const mat = new MeshStandardMaterial(); // { color: new Color(Math.random(), Math.random(), Math.random()) }
    const geom = new PlaneBufferGeometry(1, 1, 1, 1);
    this.mesh = new Mesh(geom, mat);
    this.mesh.userData.index = index;
    this.hide();
    scene.add(this.mesh);
    this.splinePos = 0;
    this.isActive = false;
    this.loading = false;
    this.lowResLoaded = false;
    this.highResLoaded = false;
    this.highResImage = undefined;
    this.updateCallback = updateCallback;
  }

  textureLoaded() {
    this.updateCallback();
    this.texture.needsUpdate = true;
    this.mesh.material.map = this.texture;
    this.mesh.material.needsUpdate = true;
    const w = this.texture.image.width;
    const h = this.texture.image.height;
    if (w > h) {
      this.mesh.scale.x = 1;
      this.mesh.scale.y = h / w;
    } else {
      this.mesh.scale.x = w / h;
      this.mesh.scale.y = 1;
    }
    this.loading = false;
    this.lowResLoaded = true;
  }

  getDepth(steps, currentDepth = 0) {
    if (steps > 1) {
      if (currentDepth === 0) {
        const listPrev = this.ll.prev.getDepth(steps - 1, -1);
        const listNext = this.ll.next.getDepth(steps - 1, +1);
        return [...listPrev, this, ...listNext];
      }
      if (currentDepth < 0) {
        const list = this.ll.prev.getDepth(steps - 1, currentDepth - 1);
        return [...list, this];
      }
      const list = this.ll.next.getDepth(steps - 1, currentDepth + 1);
      return [this, ...list];
    }
    return [this];
  }

  setDepth(curentCursor, steps, currentDepth = 0) {
    this.show();
    if (currentDepth === 0) {
      this.splinePos = curentCursor;
      this.ll.prev.setDepth(curentCursor, steps - 1, -1);
      this.ll.next.setDepth(curentCursor, steps - 1, +1);
      return;
    }

    if (steps > 0) {
      this.splinePos = curentCursor + currentDepth;
      if (currentDepth < 0) {
        this.ll.prev.setDepth(curentCursor, steps - 1, currentDepth - 1);
      } else {
        this.ll.next.setDepth(curentCursor, steps - 1, currentDepth + 1);
      }
    } else {
      if (currentDepth < 0) {
        this.ll.prev.outOfDepth(currentDepth - 1);
      } else {
        this.ll.next.outOfDepth(currentDepth + 1);
      }
    }
  }

  outOfDepth(previousDepth) {
    if (!this.isActive) return;

    this.hide();
    if (previousDepth < 0) {
      this.splinePos = -100;
      this.ll.prev.outOfDepth(previousDepth - 1);
    } else {
      this.splinePos = 100;
      this.ll.next.outOfDepth(previousDepth + 1);
    }
  }

  hide() {
    this.isActive = false;
    this.mesh.position.z = 100;
    this.mesh.position.x = 1000;
    this.splinePos = 1000;
  }

  show() {
    this.isActive = true;
    if (!this.lowResLoaded && !this.loading) {
      this.loading = true;
      this.texture = new TextureLoader().load(this.LOW_RES_URL, this.textureLoaded.bind(this));
    }
    this.mesh.position.z = 0;
  }

  update() {
    if (this.isActive) {
      const sign = this.splinePos < 0 ? -1 : 1;
      const x = Math.abs(this.splinePos);
      let pos;
      if (x > 1.8) {
        pos = 1.8 + (x - 1.8) * 0.1;
      } else {
        pos = (1 - ((1 - (x / 1.8)) ** 1.2)) * 1.8;
      }
      this.mesh.position.x = sign * pos;

      let rot = Math.PI * 0.45;
      if (x < 2.8) {
        rot *= 1 - ((1 - (x / 2.8)) ** 2);
      }
      this.mesh.rotation.set(0, -sign * rot, 0);

      let zoom = 0;
      if (x < 1.9) {
        zoom = (1 - ((x / 1.9) ** 1.5)) * 1.1;
      }
      this.mesh.position.z = zoom;
      
      // let scaleZone = 1 - Math.max(0, Math.min(1, x));
      // scaleZone = scaleZone * scaleZone;
      // console.log('Update Image Transform');
    }
  }
}
