import { OrthographicCamera } from 'three';
import {
  Raycaster,
  Color, Mesh, MeshBasicMaterial, SpotLight, PointLight, BoxBufferGeometry, PerspectiveCamera, WebGLRenderer, Scene,
} from 'three';
import CardImage from './CardImage';
import InteractionSurface from './interaction/InteractionSurface';

export default class ImageCarouselController {
  constructor() {
    const wcanvas = document.createElement('canvas');
    wcanvas.className = 'carousel-canvas';
    this.WEBGL_CANVAS = wcanvas;

    const dcanvas = document.createElement('canvas');
    dcanvas.className = 'display-canvas';
    this.DISPLAY_CANVAS = dcanvas;

    this.SCENE = new Scene();
    this.RENDERER = new WebGLRenderer({
      canvas: this.WEBGL_CANVAS,
      antialias: true,
      autoSize: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    this.RENDERER.setPixelRatio(1);
    this.RENDERER.physicallyCorrectLights = true;
    // this.RENDERER.outputEncoding = sRGBEncoding;
    this.RENDERER.setClearColor(new Color(0xffffff), 0);
    this.INTERACTION_SURFACE1 = new InteractionSurface(this, this.WEBGL_CANVAS);
    this.INTERACTION_SURFACE2 = new InteractionSurface(this, this.DISPLAY_CANVAS);
    this.CAMERA = new PerspectiveCamera(20, 1, 0.01, 100);
    // this.CAMERA = new OrthographicCamera(-100, 100, 1, -1, 0.1, 100);
    this.CAMERA.position.z = 4;
    this.CAMERA.lookAt(0, 0, 0);
    this.SCENE.add(this.CAMERA);

    this.LIST = [];

    this.RAYCASTER = new Raycaster();

    // const cube = new Mesh(new BoxBufferGeometry(), new MeshBasicMaterial({color: '#ff0000'}));
    // this.SCENE.add(cube);

    this.RAFID = undefined;

    this.prevSelectedTiles = [];
    this.setupLights();

    this.watchCanvasResize();
    this.requiresUpdate = false;
    this.hasImages = false;
  }

  requestUpdate() {
    this.requiresUpdate = true;
  }

  setImages(orderedList) {
    this.LIST = [];
    for (let i = 0; i < orderedList.length; i += 1) {
      this.LIST.push(new CardImage(i, orderedList[i].id, orderedList[i], this.SCENE, this.requestUpdate.bind(this)));
    }

    for (let i = 0; i < this.LIST.length; i += 1) {
      let iNext = i + 1;
      let iPrev = i - 1;
      if (iNext === this.LIST.length) {
        iNext = 0;
      }
      if (iPrev === -1) {
        iPrev = this.LIST.length - 1;
      }
      const ll = {
        next: this.LIST[iNext],
        prev: this.LIST[iPrev],
      };
      this.LIST[i].ll = ll;
    }

    this.CURSOR = 0;
    this.CURSOR_IDX = 0;
    this.TARGET_CURSOR = 0;
    this.TARGET_CURSOR_SMOOTH = 0;
    this.DISPLAY_CURSOR = -1;

    this.requestUpdate();
    this.hasImages = true;
    // this.updateDisplay();
  }

  setupLights() {
    const pointLight = new PointLight(0xffffff, 0.5, 0, 0.1);
    pointLight.position.z = 2;
    this.SCENE.add(pointLight);

    const intensity = 2.2;
    const penumbra = 0.99;
    const decay = 0.1;
    const distance = 0;
    const angle = 0.5;

    const spotLight1 = new SpotLight(0xffffff, intensity, distance, angle, penumbra, decay);
    spotLight1.position.z = 6;
    spotLight1.position.x = 4;
    spotLight1.position.y = -1;
    spotLight1.target.position.x = 0;
    spotLight1.target.position.z = 1.5;
    spotLight1.target.position.y = 0;
    this.SCENE.add(spotLight1);
    this.SCENE.add(spotLight1.target);

    const spotLight2 = new SpotLight(0xffffff, intensity, distance, angle, penumbra, decay);
    spotLight2.position.z = 6;
    spotLight2.position.x = -4;
    spotLight2.position.y = -1;
    spotLight2.target.position.x = 0;
    spotLight2.target.position.z = 1.5;
    spotLight2.target.position.y = 0;
    this.SCENE.add(spotLight2);
    this.SCENE.add(spotLight2.target);
  }

  start() {
    if (!this.isRunning) {
      this.update();
      this.isRunning = true;
    }
  }

  stop() {
    window.cancelAnimationFrame(this.RAFID);
    this.RAFID = undefined;
    this.isRunning = false;
  }

  displaySizeChanged(width, height) {
    this.displayWidth = width;
    this.displayHeight = height;
    this.didSizeChange = true;
    this.requestUpdate();
  }

  setDisplaySize(width, height) {
    this.didSizeChange = false;
    const w = width;
    const h = height;
    const ratio = h / w;
    const aspect = w / h;
    this.DISPLAY_CANVAS.width = w;
    this.DISPLAY_CANVAS.height = h;
    this.drawDisplayImage();
  }

  carouselSizeChanged(width, height) {
    this.carouselWidth = width;
    this.carouselHeight = height;
    this.didSizeChange = true;
  }

  setCarouselSize(width, height) {
    this.didSizeChange = false;
    let w = width;
    let h = height;
    const ratio = h / w;
    const aspect = w / h;
    // if (w > 1920) {
    //   w = 1920;
    //   h = 1920 * ratio;
    // }
    // this.CAMERA.top = 0.55;
    // this.CAMERA.bottom = -0.55;
    // this.CAMERA.left = -0.55 * aspect;
    // this.CAMERA.right = 0.55 * aspect;
    this.CAMERA.aspect = aspect;
    this.CAMERA.updateProjectionMatrix();
    this.RENDERER.setSize(w, h, false);
  }

  updateCursor() {
    let dist = this.TARGET_CURSOR - this.CURSOR;
    if (Math.abs(dist) < 0.1) {
      this.CURSOR = this.TARGET_CURSOR;
      return;
    }
    // If the dist is more then half the entire list then we need to go the other way and calculate the new distance
    // if (Math.abs(dist) > this.LIST.length * 0.5) {
    //   if (dist > 0) {
    //     dist -= this.LIST.length;
    //   } else {
    //     dist += this.LIST.length;
    //   }
    // }

    this.CURSOR += dist * 0.1;
    this.CURSOR_IDX = Math.round(this.CURSOR) % this.LIST.length;
    // console.log(`Cursor ${this.CURSOR}`);
    // console.log(this.LIST.length);
    // console.log(this.CURSOR_IDX);
    if (this.CURSOR_IDX < 0) {
      this.CURSOR_IDX = this.LIST.length + this.CURSOR_IDX;
      // this.CURSOR = this.LIST.length + this.CURSOR;
    } else if (this.CURSOR_IDX >= this.LIST.length) {
      this.CURSOR_IDX -= this.LIST.length;
      // this.CURSOR -= this.LIST.length;
    }
    this.requestUpdate();
  }

  updateTiles() {
    this.prevSelectedTiles.forEach((tile) => { tile.hide(); });
    const remainder = Math.round(this.CURSOR) - this.CURSOR;
    if (!this.LIST[this.CURSOR_IDX]) return;
    this.LIST[this.CURSOR_IDX].setDepth(remainder, 18);
    const tiles = this.LIST[this.CURSOR_IDX].getDepth(19);

    tiles.forEach((tile) => { tile.update(); });
    this.prevSelectedTiles = tiles;
  }

  update() {
    // console.log('update');
    this.RAFID = requestAnimationFrame(this.update.bind(this));
    if (this.requiresUpdate) {
      if (this.didSizeChange) {
        this.setCarouselSize(this.carouselWidth, this.carouselHeight);
        this.setDisplaySize(this.displayWidth, this.displayHeight);
      }
      this.updateCursor();
      this.updateTiles();
      // this.CAMERA.rotateY(0.01);
      this.RENDERER.render(this.SCENE, this.CAMERA);

      this.updateDisplay();
      // console.log(`Cursor ${this.CURSOR}\nTarget ${this.TARGET_CURSOR}\nDisplay ${this.DISPLAY_CURSOR}`);
      if (this.CURSOR === this.TARGET_CURSOR) {
        this.requiresUpdate = false;
      }
    }
  }

  updateDisplay() {
    // console.log(`Attempting... display update\nDisplaying ${this.CURSOR_IDX}\nBounded 0 -> ${this.LIST.length - 1}`);
    if (this.TARGET_CURSOR === Math.round(this.CURSOR) && this.TARGET_CURSOR !== this.DISPLAY_CURSOR) {
      // console.log(`_____ Success... display update\nDisplaying ${this.CURSOR_IDX}\nBounded 0 -> ${this.LIST.length - 1}`);
      this.LOADING_CALLBACK();
      this.DISPLAY_CURSOR = this.CURSOR_IDX;
      if (this.LIST[this.DISPLAY_CURSOR].highResImage) {
        this.drawDisplayImage();
        this.LOADED_CALLBACK();
      } else {
        const cardImage = this.LIST[this.DISPLAY_CURSOR];
        cardImage.highResImage = new Image();
        const _this = this;
        cardImage.highResImage.onload = () => {
          _this.drawDisplayImage();
          _this.LOADED_CALLBACK();
        };
        cardImage.highResImage.src = cardImage.HIGH_RES_URL;
      }
    }
  }

  drawDisplayImage() {
    if (!this.LIST[this.DISPLAY_CURSOR]) return;
    const image = this.LIST[this.DISPLAY_CURSOR].highResImage;
    if (!image) return;
    const CTX = this.DISPLAY_CANVAS.getContext('2d');
    CTX.clearRect(0, 0, this.displayWidth, this.displayHeight);

    const canvasAspect = this.displayWidth / this.displayHeight;
    const imageAspect = image.width / image.height;
    let dw;
    let dh;
    if (canvasAspect > imageAspect) {
      dw = this.displayHeight * imageAspect;
      dh = this.displayHeight;
    } else {
      dw = this.displayWidth;
      dh = this.displayWidth / imageAspect;
    }
    const dx = this.displayWidth * 0.5 - dw * 0.5;
    const dy = this.displayHeight * 0.5 - dh * 0.5;
    CTX.drawImage(image, dx, dy, dw, dh);
  }

  moveLeft() {
    this.TARGET_CURSOR -= 1;
    this.TARGET_CURSOR_SMOOTH -= 1;
    this.requestUpdate();
  }

  moveRight() {
    this.TARGET_CURSOR += 1;
    this.TARGET_CURSOR_SMOOTH += 1;
    this.requestUpdate();
  }

  onInteractionClicked(canvas, x, y) {
    if (canvas === this.DISPLAY_CANVAS) return;
    const sx = (x / this.carouselWidth) * 2 - 1;
	  const sy = -(y / this.carouselHeight) * 2 + 1;
    this.RAYCASTER.setFromCamera({ x: sx, y: sy }, this.CAMERA);
    const intersects = this.RAYCASTER.intersectObjects( this.SCENE.children );
    let intersect;
    for (let i = 0; i < intersects.length; i += 1) {
      if (intersects[i].object.type === 'Mesh') {
        intersect = intersects[i];
        break;
      }
    }
    if (!intersect) return;
    const dist = intersect.object.userData.index - this.CURSOR_IDX;
    // console.log(`Clicked idx ${intersect.object.userData.index}`);
    // console.log(`Cursor idx ${this.CURSOR_IDX}`);
    // console.log(`Display idx ${this.DISPLAY_CURSOR}`);
    // console.log(`Target cursor idx ${this.TARGET_CURSOR}`);
    // console.log(`Dist ${dist}`);
    if (dist === 0) return;
    if (Math.abs(dist) < this.LIST.length * 0.5) {
      // console.log(`No wrap click, moving ${dist}`);
      this.TARGET_CURSOR += dist;
    } else {
      // console.log(`Wrap click,\n
      // Clicked idx ${intersect.object.userData.index}\n
      // Target cursor idx ${this.TARGET_CURSOR}\n
      // Dist ${dist}`);
      if (dist > 0) {
        this.TARGET_CURSOR -= (this.LIST.length - dist);
      } else {
        this.TARGET_CURSOR += (this.LIST.length + dist);
      }
    }
    this.TARGET_CURSOR_SMOOTH = this.TARGET_CURSOR;
    this.requestUpdate();
  }

  onInteractionRotateMouse(dx, dy) {
    const { CAMERA_ORBIT } = this;

    this.TARGET_CURSOR_SMOOTH += -dx * 0.01;
    this.TARGET_CURSOR = Math.round(this.TARGET_CURSOR_SMOOTH);
    this.requestUpdate();
  }

  onInteractionZoomPinch(dZoom) {
    const { CAMERA_ORBIT } = this;
    CAMERA_ORBIT.zoom(dZoom);
  }

  onInteractionRotateTouch(changedTouches, dx, dy) {

    this.TARGET_CURSOR_SMOOTH += -dx * 0.025;
    this.TARGET_CURSOR = Math.round(this.TARGET_CURSOR_SMOOTH);
    this.requestUpdate();
  }

  onInteractionZoomWheel(event, distance) {
    const { CAMERA_ORBIT } = this;

    this.TARGET_CURSOR_SMOOTH += distance;
    this.TARGET_CURSOR = Math.round(this.TARGET_CURSOR_SMOOTH);
    this.requestUpdate();
  }

  watchCanvasResize() {
    this.carouselWidth = 0;
    this.carouselHeight = 0;
    this.didSizeChange = false;
    this.resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          if (entry.contentBoxSize[0]) {
            this.carouselSizeChanged(
              entry.contentBoxSize[0].inlineSize,
              entry.contentBoxSize[0].blockSize,
            );
          } else {
            this.carouselSizeChanged(
              entry.contentBoxSize.inlineSize,
              entry.contentBoxSize.blockSize,
            );
          }
        } else {
          this.carouselSizeChanged(entry.contentRect.width, entry.contentRect.height);
        }
      }
    });

    this.resizeObserver.observe(this.WEBGL_CANVAS);
    this.resizeObserver2 = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          if (entry.contentBoxSize[0]) {
            this.displaySizeChanged(
              entry.contentBoxSize[0].inlineSize,
              entry.contentBoxSize[0].blockSize,
            );
          } else {
            this.displaySizeChanged(
              entry.contentBoxSize.inlineSize,
              entry.contentBoxSize.blockSize,
            );
          }
        } else {
          this.displaySizeChanged(entry.contentRect.width, entry.contentRect.height);
        }
      }
    });

    this.resizeObserver2.observe(this.DISPLAY_CANVAS);
  }

  getActiveCardId() {
    return this.LIST[this.CURSOR_IDX].OPTIONS.id;
  }
}
