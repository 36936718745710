<template lang="html">
  <div class="tabs">
    <ul class='tabs__header'>
      <li v-for='(tab, index) in tabs'
        :key='tab.title'
        @click='selectTab(index)'
        :class='{"tab__selected": (index == selectedIndex)}'>
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'light'
    }
  },
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: []         // all of the tabs
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>

<style lang="css">

  .tabs {
    --tab-bg-color: #ffffff;
    --tab-text-color: #333;
    --tab-active-bg-color: #ffffff;
    --tab-active-text-color: #333;
  }

  ul.tabs__header {
    display: block;
    list-style: none;
    margin: 0.5em;
    padding: 0;
  }

  ul.tabs__header > li {
    padding: 1rem 1rem;
    margin: 0;
    display: inline-block;
    margin: 0.25em;
    cursor: pointer;
  }

  ul.tabs__header > li.tab__selected {
    font-weight: bold;
    /* border-bottom: 0.5rem solid transparent; */
    z-index: 1000;
    background-color: var(--tab-active-bg-color);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .15);
    /* border-radius: 5px 5px 0px 0px; */
    /* border: 1px solid #f0f0f0; */
    /* border-bottom: 1rem solid #b1444400; */
  }

  .tab {
    display: inline-block;
    color: var(--tab-text-color);
    padding: 1.5rem 1.5rem;
    min-width: 80px;
    min-height: 40px;
  }

  .tabs .tab{
    background-color: var(--tab-active-bg-color);
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
  }

  .tabs li {
    background-color: var(--tab-bg-color);
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
  }

  .tabs li.tab__selected {
    text-decoration: underline;
  }

</style>