<template>
  <div class="home">
    <div id="landing-hero-container" class="static-page">
      <div class="uk-cover-container uk-height-medium">
        <img src='../assets/images/banner/pp-hero-banner.png' uk-cover>
      </div>
      <div class="home-title">Correspondance Pierre Pagès 1914-1919</div>
      <div class="intro-block uk-container">
        <div class="intro-header">Ce site regroupe environ 300 cartes postales envoyées durant la première guerre mondiale par Pierre Pagès, sa famille et ses amis.</div>
        <div class="intro-text" ref="intoText">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;La correspondance est en grande partie ordinaire et simple, et tient souvent à la santé des individus mais elle est explicite quant à la description des situations et évènements vécus et elle montre ses sentiments profonds envers sa famille et amis. Elle offre également une fenêtre touchante sur la première guerre mondiale, vécue par un simple soldat. Au début, la certitude d’une guerre courte. Puis, le souhait réitéré qu’elle cesse rapidement.  Les longues marches, le mal au pied, la censure, une visite du Président de la République,  le grand chaud, le grand froid, la faim, les ripailles au champagne pour un jour de l’an, la construction des tranchées, le déblaiement des tranchées,  la peur des tranchées…</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;En plus des cartes postales, nous avons son carnet de voyage (voir sous Documents) où il relate son itinéraire, ses combats et les horreurs de la guerre.</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;Pierre est mobilisé dès le début de la guerre en août 1914, de la classe de 1908 simple soldat (ou poilu) et il ne sera démobilisé que le 10 Juillet 1919.  Il fera part de beaucoup de batailles, en Moselle, en Belgique, en Alsace et dans la Somme.</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;Il est blessé à plusieurs reprises, et fait de multiples séjours à l’hôpital et il est chaque fois renvoyé au front. Par miracle il sort indemne des longues et sanglantes batailles du Hartmannswillerkopf en Alsace en 1915/16.  La correspondance est plus dense à cette période et les cartes plus intéressantes. Il sera blessé une dernière fois, à Cléry dans la Somme.  A la suite de cette blessure, Pierre va d’hôpital en hôpital jusqu’en avril 1918.  De ce fait, la correspondance se raréfie et il n’y a qu’une poignée de cartes datées de 1917/18. A partir d’avril 1918 il est affecté à la 12ème Section d’Aérostation.  En janvier 1919 il est muté dans des bureaux à Paris où il travaille comme secrétaire à la démobilisation.</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;Bien qu’il y ait 300 cartes exploitées, cette correspondance est loin d’être complète, il y manque beaucoup de cartes et l’on apprend en lisant certaines d’entre elles que tous les échanges fait par lettres ont été détruits car le papier se décomposait dans les poches de la capote.  Assez tôt, Pierre décide de renvoyer les cartes qu’il reçoit avec celles qu’il envoie à son épouse Marinette et à sa fille Lucienne afin de les conserver.  Toutes ces cartes ont été conservées plus de cent ans dans un tiroir de buffet.  D’abord soigneusement rangées dans un grand album original en vieux cuir brun puis, au fil du temps, elles ont fini dans une grande boîte à biscuits.</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;A la mort prématurée de leur fille Lucienne en 1933, Pierre et Marinette recueillent son fils Guy Catrix qui deviendra l’un des premiers confidents de Pierre à qui il racontera les épreuves endurées durant la première guerre mondiale. C’est en grande partie grâce à lui que ces cartes sont arrivées indemnes jusqu’à nos jours.</p>
        </div>
        <div class="intro-lire-plus" ref="lirePlusButton" v-on:click="readMoreClicked()">Lire plus &gt;</div>
      </div>
      <div class="uk-container uk-flex uk-flex-row uk-flex-wrap uk-flex-center home-buttons uk-text-left">
        <div class="uk-width-1-1 uk-width-1-3@m uk-card uk-card-default uk-card-hover uk-card-body" v-on:click="linkClicked('galerie')">
            <h2 class="uk-card-title">Galerie</h2>
            <!-- <p>Voir la Gallerie des cartes postales</p> -->
        </div>
        <div class="uk-width-1-1 uk-width-1-3@m uk-card uk-card-default uk-card-hover uk-card-body" v-on:click="linkClicked('cartes')">
            <h2 class="uk-card-title">Cartes Postales</h2>
            <!-- <p>Sélection et navigation des cartes </p> -->
        </div>
        <div class="uk-width-1-1 uk-width-1-3@m uk-card uk-card-default uk-card-hover uk-card-body" v-on:click="linkClicked('documents')">
            <h2 class="uk-card-title">Documents</h2>
            <!-- <ul>
              <li>Itinéraire de guerre</li>
              <li>Chansons de soldats</li>
              <li>Papiers Millitaires</li>
            </ul> -->
            <!-- <p>Itinéraire de guerre</p>
            <p>Chansons de soldats</p>
            <p>Papiers Millitaires</p> -->
        </div>
        <div class="uk-width-1-1 uk-width-1-3@m uk-card uk-card-default uk-card-hover uk-card-body" v-on:click="linkClicked('contact')">
            <h2 class="uk-card-title">Contact</h2>
            <!-- <p>Photo colorisée du 1<sup>er</sup> Janvier 1916</p> -->
        </div>
        <!-- <div class="uk-width-1-1 uk-width-1-3@m uk-card uk-card-default uk-card-hover uk-card-body">
            <h3 class="uk-card-title">Qui est qui</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        </div> -->
      </div>
    </div>
    
    <p-p-footer></p-p-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import PPFooter from '../components/PPFooter.vue';

export default {
  name: 'Home',
  components: {
    PPFooter
  },
  methods: {
    linkClicked(pageLink) {
      // console.log('linkClicked', pageLink);
      this.$router.push(`/${pageLink}`);
    },
    readMoreClicked() {
      this.readMore = !this.readMore;
      if (this.readMore) {
        this.$refs.lirePlusButton.innerText = 'Lire moins <';
        this.$refs.intoText.classList.add('show');
      } else {
        this.$refs.lirePlusButton.innerText = 'Lire plus >';
        this.$refs.intoText.classList.remove('show');
      }
    },
  },
  data: () => ({
    readMore: false,
  }),
};
</script>

<style lang="scss">
.home{
  position: relative;
  // max-height: calc(100% - (var(--nav-bar-height) + 2 * var(--nav-bar-vert-margin)));
  width: 100%;
}

.home-title{
  font-size: 2.5em;
  margin-top: 1em;
}
#landing-hero-container{

  .intro-block{
    text-align: left;

    // padding-left: 35em;
    // padding-right: 35em;

    .intro-header{
      font-size: 1.5em;
      margin-top: 1em;
    }

    .intro-text{
      overflow-y: hidden;
      max-height: 0px;
      margin-top: 1em;
      font-size: 1.5em;

      // 

      &.show{
        max-height: 5000px;
        // 
      }
    }

    .intro-lire-plus{
      float: right;
      font-size: 1em;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .home-buttons {
    margin-top: 3em;
    // height: 40vh;
    margin-bottom: 3em;
    overflow-y: auto;
  }

  .uk-card {
    margin: 1em;
    height: 14em;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    cursor: pointer;

    .uk-card-title{
      font-size: 2em;
      text-decoration: underline;
    }
  }
}
</style>
