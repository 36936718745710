<template>
  <div class="uk-container correspondance" v-if="record">
    <div class="card-navigation">
      <div class="card-navigation-button-container">
        <button type="button" v-if="prevCardAvailable" @click="prevCard">&lt; Carte précédente</button>
      </div>
      <div class="card-navigation-button-container">
        <button type="button" v-if="nextCardAvailable" @click="nextCard">Carte suivante &gt;</button>
      </div>
    </div>
    <div class="card-header">
      <div class="people">
        <h3 class="person">
          Expéditeur: {{record.personFrom}} - {{record.locFrom}}
        </h3>
        <h3 class="person">
          Destinataire: {{record.personTo}} - {{record.locTo}}
        </h3>
      </div>
      <h3 class="date">
        {{dateCleanup(record.date)}}
      </h3>
    </div>

    <div class="card-images">
      <div class="card-image"><img class="left" :src="`/images/${record.img1}`"/></div>
      <div class="card-image"><img class="right" :src="`/images/${record.img2}`"/></div>
    </div>
    <p class="transcript" v-html="record.transcription"></p>
    <div class="card-navigation">
      <div class="card-navigation-button-container">
        <button type="button" v-if="prevCardAvailable" @click="prevCard">&lt; Carte précédente</button>
      </div>
      <div class="card-navigation-button-container">
        <button type="button" v-if="nextCardAvailable" @click="nextCard">Carte suivante &gt;</button>
      </div>
    </div>
    <p-p-footer></p-p-footer>
  </div>
</template>

<script>
import PPFooter from '../components/PPFooter.vue';
import Dispatcher from '../dispatcher/dispatcher';

export default {
  name: 'Carte',
  components: { PPFooter },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  beforeDestroy() {
  },
  // computed: {
  //   postcards: {
  //     get() {
  //       return this.$store.state.postcards;
  //     },
  //   },
  // },
  // watch: {
  //   postcards(newPostcards) {
  //     if (newPostcards.length > 0) {
  //       // console.log("Card page (watch)");
  //       // console.log(newPostcards);
        
  //       this.records = newPostcards.filter((record) => record.type === 'Postcard');
  //       this.records = this.records.sort((a, b) => {
  //         return a.date.localeCompare(b.date);
  //       });
  //       this.record = this.records.find((record) => record._id === this.id);
  //     }
  //   },
  // },
  mounted() {
    Dispatcher.on('dataReady', () => {
      this.postcards = this.$store.state.postcards;
      this.records = this.postcards.filter((record) => record.type === 'Postcard');
      this.records = this.records.sort((a, b) => {
        return a.date.localeCompare(b.date);
      });
      this.record = this.records.find((record) => record._id === this.id);
      this.mountedButtonStatus();
    });
    if (this.$store.state.postcards) {
      this.postcards = this.$store.state.postcards;
      this.records = this.postcards.filter((record) => record.type === 'Postcard');
      this.records = this.records.sort((a, b) => {
        return a.date.localeCompare(b.date);
      });
      this.record = this.records.find((record) => record._id === this.id);
      this.mountedButtonStatus();
    }
  },
  data: () => ({
    records: undefined,
    record: undefined,
    nextCardAvailable: true,
    prevCardAvailable: true,
  }),
  methods: { 
    dateCleanup(date) {
      const dateParams = date.split('-');
      const year = dateParams[0];
      const month = dateParams[1];
      const day = dateParams[2];
      return `${day}/${month}/${year}`;
    },
    mountedButtonStatus() {
      if (!this.records) {
        return;
      }
      const sorted = this.postcards.sort((a, b) => {
        return a.date.localeCompare(b.date);
      });
      const index = sorted.findIndex((record) => record._id === this.id);

      if (index === 0) {
        this.prevCardAvailable = false;
      } else {
        this.prevCardAvailable = true;
      }

      if (index === sorted.length - 1) {
        this.nextCardAvailable = false;
      } else {
        this.nextCardAvailable = true;
      }
    },
    nextCard() {
      if (!this.records) {
        return;
      }
      const sorted = this.postcards.sort((a, b) => {
        return a.date.localeCompare(b.date);
      });
      const index = sorted.findIndex((record) => record._id === this.id);
      if (index + 1 <= sorted.length - 1 && index > -1) {
        this.$router.push(`/carte/${sorted[index + 1]._id}`);
        this.record = sorted[index + 1];

        if (index + 1 === sorted.length - 1) {
          this.nextCardAvailable = false;
        } else {
          this.nextCardAvailable = true;
        }
        this.prevCardAvailable = true;
      }
    },
    prevCard() {
      if (!this.records) {
        return;
      }
      const sorted = this.postcards.sort((a, b) => {
        return a.date.localeCompare(b.date);
      });
      const index = sorted.findIndex((record) => record._id === this.id);
      if (index > 0) {
        this.$router.push(`/carte/${sorted[index - 1]._id}`);
        this.record = sorted[index - 1];

        if (index - 1 === 0) {
          this.prevCardAvailable = false;
        } else {
          this.prevCardAvailable = true;
        }
        this.nextCardAvailable = true;
      }
    },
  },
};
</script>

<style lang="scss">



.correspondance {
  text-align: left;
  
  .card-navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 0 0 1rem 0;
    padding: 2.5em 1em;

    .card-navigation-button-container{
      display: flex;
      justify-content: center;
      align-items: center;

      button{
        // width: 250px;
        // height: 2rem;
        padding: 0.5em 1em;
        background-color: var(--primary-color);
        border: 1px solid #ffffff;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.5s;
        &:hover{
          background-color: var(--primary-accent-color);
          border: 1px solid #222222;
          color: #222222;
        }

        color: #ffffff;
        font-size: 1em;
        font-weight: bold;
      }
    }
  }

  .card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem 0;

    .people{
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // margin: 0 0 1rem 0;
      
      h3{
        margin-top: 0.1em;
        margin-bottom: 0.1em;
      }
      .person {
        margin-bottom: 0;

        @media screen and (max-width: 640px){
          font-size: 1em;
        }
      }
    }
    .date{
      @media screen and (max-width: 640px){
        font-size: 1.25em;
      }
    }
  }

  .card-images{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 0 1rem 0;

    .card-image{
      width: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        max-height: 400px;
        box-shadow: 0 5px 15px rgba(0,0,0,.08);
        
        transition: transform 0.5s;
        &.left{
          &:hover {
            transform: scale(2) translate(25%, 25%);
          }
        }

        &.right{
          &:hover {
            transform: scale(2) translate(-25%, 25%);
          }
        }
      }
    }
  }

  .transcript{
    font-family: transcriptFont;
    // font-weight: bold;
    font-size: 1.5em;
    line-height: 1.5em;
    padding: 1em;
    // background-color: #eae1d3;
    box-shadow: 0 5px 15px rgba(0,0,0,.08);

    margin-bottom: 0em;
  }
}
</style>
