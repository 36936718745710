<template>
  <div class="uk-container filter-page">
    <div class="uk-grid-collapse uk-child-width-expand@s uk-text-center" uk-grid>
      <div ref="filtersArea" class="uk-width-1-4@m">
        <div class="filters uk-width-1-1 uk-width-4-5@m">
          <card-filters :filteriser="filteriser"></card-filters>
        </div>
      </div>
      <div ref="cardsArea">
        <card-list :filteriser="filteriser" />
      </div>
    </div>
    <p-p-footer></p-p-footer>
  </div>
</template>

<script>
import axios from "axios";
import CardFilters from "../components/CardFilters/CardFilters.vue";
import CardList from "../components/CardFilters/CardList.vue";
import PPFooter from "../components/PPFooter.vue";
import Dispatcher from '../dispatcher/dispatcher';

export default {
  name: "Cartes",
  components: { CardFilters, CardList, PPFooter },
  beforeDestroy() {
    // console.log("Cartes beforeDestroy()");
    // this.$refs.filtersArea.classList.remove('show-filters');
    this.$refs.cardsArea.classList.remove("show-cards");
  },
  computed: {
    postcards: {
      get() {
        return this.$store.state.postcards;
      },
    },
  },
  watch: {
    // postcards(newPostcards) {
      
    //   // if (!this.$store.state.filteriser) {
    //   //   // this.$store.state.filteriser = new Filteriser(this.$store, []);
    //   //   this.filteriser = this.$store.state.filteriser;
    //   // }
    //   if (newPostcards.length > 0 && this.filteriser) {
    //     console.log("Cartes watch newPostcards");
    //     this.filteriser.setRecords(newPostcards);
    //     // console.log("New postcards (watch)");
    //     // console.log(newPostcards);
    //   } else {
    //     this.records = newPostcards;
    //   }
    // },
  },
  mounted() {
    Dispatcher.on("dataReady", () => {
      // console.log("Cartes dataReady");
      // this.$refs.filtersArea.classList.add('show-filters');
      this.$refs.cardsArea.classList.add("show-cards");
      this.filteriser = this.$store.state.filteriser;
    });

    if (this.$store.state.filteriser) {
      this.$refs.cardsArea.classList.add("show-cards");
      this.filteriser = this.$store.state.filteriser;
    }
  },
  data: () => ({
    records: [],
    filteriser: undefined,
  }),
  methods: {
  },
};
</script>

<style lang="scss">
.filter-page {
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);

  .filters {
    position: sticky;
  }

  @keyframes filters-show {
    0% {
      transform: translate(-200px, 0px);
      opacity: 0;
    }
    100% {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }

  @keyframes cards-show {
    0% {
      transform: translate(200px, 0px);
      opacity: 0;
    }
    100% {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }

  .show-filters {
    animation-name: filters-show;
    animation-duration: 1s;
  }

  .show-cards {
    animation-name: cards-show;
    animation-duration: 1s;
  }
}
</style>
