export default class InteractionSurface {
  constructor(controller, canvas) {
    this.CONTROLLER = controller;
    this.CANVAS = canvas;

    // Touch Zoom
    this.touchZoomMinDelta = 25.0 / window.devicePixelRatio;
    this.touchZoomOutputScalar = 0.01;
    this.isTouchToZoom = false;
    this.touchZoomScale = 0;

    // Init
    this.positionPreviousX = -1;
    this.positionPreviousY = -1;

    this.isDown = false;

    // Start
    this.enable();
  }

  // __________________________________________________________ Enable / Disable

  enable() {
    const { CANVAS } = this;

    CANVAS.onmousedown = this.onMouseDown.bind(this);
    CANVAS.onmousemove = this.onMouseMove.bind(this);
    CANVAS.onmouseup = this.onMouseUp.bind(this);
    CANVAS.onmouseout = this.onMouseOut.bind(this);

    CANVAS.addEventListener('touchstart', this.onTouchStart.bind(this), false);
    CANVAS.addEventListener('touchmove', this.onTouchMove.bind(this), false);
    CANVAS.addEventListener('touchend', this.onTouchEnd.bind(this), false);

    CANVAS.onwheel = this.onWheel.bind(this);
  }

  // _____________________________________________________________________ Start

  onMouseDown(event) {
    event.preventDefault();

    const CANVAS_RECTANGLE = this.CANVAS.getBoundingClientRect();

    const X = event.clientX - CANVAS_RECTANGLE.left;
    const Y = event.clientY - CANVAS_RECTANGLE.top;

    this.onInteractionStart(
      X,
      Y,
    );
    this.positionStartX = X;
    this.positionStartY = Y;
  }

  onTouchStart(event) {
    // event.preventDefault();

    const TOUCH = event.changedTouches[0];
    const CANVAS_RECTANGLE = this.CANVAS.getBoundingClientRect();

    this.onInteractionStart(
      TOUCH.clientX - CANVAS_RECTANGLE.left,
      TOUCH.clientY - CANVAS_RECTANGLE.top,
    );
  }

  onInteractionStart(x, y) {
    this.positionPreviousX = x;
    this.positionPreviousY = y;

    this.isDown = true;
  }

  // ______________________________________________________________________ Move

  onMouseMove(event) {
    event.preventDefault();

    if (this.isDown === false) {
      return;
    }

    const CANVAS_RECTANGLE = this.CANVAS.getBoundingClientRect();

    const X = event.clientX - CANVAS_RECTANGLE.left;
    const Y = event.clientY - CANVAS_RECTANGLE.top;

    this.CONTROLLER.onInteractionRotateMouse(
      X - this.positionPreviousX,
      Y - this.positionPreviousY,
    );

    this.positionPreviousX = X;
    this.positionPreviousY = Y;
  }

  onTouchMove(event) {
    // console.log('touch move');
    if (this.isDown === false) {
      return;
    }

    const CANVAS_RECTANGLE = this.CANVAS.getBoundingClientRect();

    // Zoom ?
    let didZoom = false;
    const SCALE = this.getTouchScale(event);
    if (this.isTouchToZoom === false) {
      // Store
      this.touchZoomScale = SCALE;
      this.isTouchToZoom = true;
    } else {
      // Zoom ?
      const difference = this.touchZoomScale - SCALE;
      if (Math.abs(difference) > this.touchZoomMinDelta) {
        this.CONTROLLER.onInteractionZoomPinch(
          difference * this.touchZoomOutputScalar,
        );
        this.touchZoomScale = SCALE;
        didZoom = true;
      }
    }

    // Abandon rotate if zoomed
    if (didZoom === true) {
      return;
    }

    // Rotate
    const TOUCHES = event.targetTouches;
    let x = 0;
    let y = 0;

    for (let i = 0; i < TOUCHES.length; i += 1) {
      x += TOUCHES[i].clientX - CANVAS_RECTANGLE.left;
      y += TOUCHES[i].clientY - CANVAS_RECTANGLE.top;
    }

    x /= TOUCHES.length;
    y /= TOUCHES.length;

    const HALT_EVENT = this.CONTROLLER.onInteractionRotateTouch(
      event.changedTouches,
      x - this.positionPreviousX,
      y - this.positionPreviousY,
    );

    if (HALT_EVENT === true) {
      event.preventDefault();
    }

    // event.preventDefault();

    this.positionPreviousX = x;
    this.positionPreviousY = y;
  }

  // _______________________________________________________________________ End

  onMouseUp(event) {
    event.preventDefault();

    const CANVAS_RECTANGLE = this.CANVAS.getBoundingClientRect();

    this.onInteractionEnd(
      event.clientX - CANVAS_RECTANGLE.left,
      event.clientY - CANVAS_RECTANGLE.top,
    );
    
  }

  onTouchEnd(event) {
    // event.preventDefault();

    const TOUCH = event.changedTouches[0];
    const CANVAS_RECTANGLE = this.CANVAS.getBoundingClientRect();
    this.isTouchToZoom = false;

    this.onInteractionEnd(
      TOUCH.clientX - CANVAS_RECTANGLE.left,
      TOUCH.clientY - CANVAS_RECTANGLE.top,
    );
  }

  onMouseOut(event) {
    event.preventDefault();

    const CANVAS_RECTANGLE = this.CANVAS.getBoundingClientRect();

    this.onInteractionEnd(
      event.clientX - CANVAS_RECTANGLE.left,
      event.clientY - CANVAS_RECTANGLE.top,
    );
  }

  onInteractionEnd(x, y) {
    if (this.isDown === false) {
      return;
    }
    this.positionPreviousX = x;
    this.positionPreviousY = y;
    this.isDown = false;
    if (Math.abs(this.positionStartX - x) < 5 && Math.abs(this.positionStartY - y) < 5) {
      this.CONTROLLER.onInteractionClicked(this.CANVAS, x, y);
    }
  }

  // _____________________________________________________________________ Wheel

  onWheel(event) {
    event.preventDefault();
    // Zoom
    if (event.deltaY < 0.0) {
      this.CONTROLLER.onInteractionZoomWheel(event, -1.0);
    } else if (event.deltaY > 0.0) {
      this.CONTROLLER.onInteractionZoomWheel(event, 1.0);
    }
  }

  // ______________________________________________________________________ Util

  /* eslint-disable class-methods-use-this */

  getTouchScale(event) {
    let dx;
    let dy;
    let diff;
    let i;
    let j;
    let touchScale = 0;

    // Find the largest distance between fingers and use that as the scale.
    for (i = 0; i < event.targetTouches.length; i += 1) {
      for (j = 0; j < event.targetTouches.length; j += 1) {
        dx = event.targetTouches[i].clientX - event.targetTouches[j].clientX;
        dy = event.targetTouches[i].clientY - event.targetTouches[j].clientY;

        diff = Math.sqrt(dx * dx + dy * dy);

        if (diff > touchScale) touchScale = diff;
      }
    }

    return touchScale;
  }
}
