class Dispatcher {
  static handlers = {};

  static on(eventName, callback) {
    if (!this.handlers[eventName]) {
      this.handlers[eventName] = [];
    }
    this.handlers[eventName].push(callback);
  }

  static dispatch(eventName, arg1, arg2) {
    if (this.handlers[eventName]) {
      this.handlers[eventName].forEach((callback) => {
        callback(arg1, arg2);
      });
    }
  }
}

export default Dispatcher;
