<template>
  <div class="documents uk-container">
    <tabs>
      <tab title="Carnet de Voyage"><carnet /></tab>
      <tab title="Papiers Militaires"><papiers-militaire /></tab>
      <tab title="Chansons"><chansons /></tab>
      <tab title="Colorisation"><colorisation /></tab>
      <tab title="Famille Pagès-Azéma"><auteurs-princip /></tab>
    </tabs>
    
    <p-p-footer></p-p-footer>
  </div>
</template>

<script>
import Tab from '../components/Tabs/Tab.vue';
import Tabs from '../components/Tabs/Tabs.vue';
import Chansons from './documents/Chansons.vue';
import Carnet from './documents/Carnet.vue';
import Colorisation from './documents/Colorisation.vue';
import AuteursPrincip from './documents/AuteursPrincip.vue';
import PapiersMilitaire from './documents/PapiersMilitaire.vue';

import PPFooter from '../components/PPFooter.vue';

export default {
  name: 'Documents',
  components: { Tabs, Tab, Chansons, Carnet, Colorisation, AuteursPrincip, PapiersMilitaire, PPFooter },
};
</script>

<style lang="scss">
.documents {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: left;
  img {
    width: 50%;
  }
}
</style>
