<template>
    <div class="papiers-militaire">
        <h1>Papiers Militaires</h1>
        <h3>Livret de troupe:</h3>
        <img src="/pm/livret de troupe 1.jpg" />
        <img src="/pm/livret de troupe 2+3.jpg" />
        <img src="/pm/livret de troupe 4+5.jpg" />
        <img src="/pm/livret de troupe 4+5b.jpg" />
        <img src="/pm/livret de troupe 5.jpg" />
        <br/>
        <h3>Exercice No8:</h3>
        <p>Exercice No8 pages 1 et 4:</p>
        <img src="/pm/exercice no8 1+4.jpg" />
        <p>Exercice No8 pages 2 et 3:</p>
        <img src="/pm/exercice no8 2+3.jpg" />
        <p>Exercice No8 page avant:</p>
        <img src="/pm/exercice No8 recto.jpg" />
        <p>Exercice No8 page arrière:</p>
        <img src="/pm/exercice No8 verso.jpg" />

    </div>
</template>

        
<script>
export default {
  name: 'PapiersMilitaire',
};
</script>

<style lang="scss" scoped>
.papiers-militaire {
  width: 100%;


  img {
    width: 100%;
    height: auto;
  
  }
}

.name {
  font-weight: bold;
}
</style>