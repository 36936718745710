<template>
    <div class="filter-table">
        <div class="uk-flex uk-flex-column" v-if="filteriser">
            <div class="uk-width-1-1 list-item uk-flex uk-flex-column">
                <div class=" uk-flex uk-flex-row uk-flex-between uk-text-left ">
                    <div class="uk-width-1-6@s uk-width-1-3 title">Date</div>
                    <div class="uk-width-1-6@s uk-width-1-3 title">Expéditeur</div>
                    <div class="uk-width-1-6@s title superfluous">Destinataire</div>
                    <div class="uk-width-1-6@s uk-width-1-3 title">Envoyé de</div>
                    <div class="uk-width-1-6@s title superfluous">Reçu à</div>
                </div>
            </div>
            <div :class="'uk-width-1-1 list-item uk-flex uk-flex-column ' + ((inspectID === record._id) ? 'inspecting' : '')" v-bind:key="record._id" v-for="record in filteredList" @click="debug(record._id)" :id="record._id">
                <div class=" uk-flex uk-flex-row uk-flex-between uk-text-left ">
                    <div class="uk-width-1-6@s uk-width-1-3">{{date(record.date)}}</div>
                    <div class="uk-width-1-6@s uk-width-1-3">{{record.personFrom}}</div>
                    <div class="uk-width-1-6@s superfluous">{{record.personTo}}</div>
                    <div class="uk-width-1-6@s uk-width-1-3">{{record.locFrom}}</div>
                    <div class="uk-width-1-6@s superfluous">{{record.locTo}}</div>
                </div>
                <div v-if="(inspectID === record._id)" class=" uk-flex uk-flex-row uk-flex-between uk-text-left list-item-details">
                    <div class="uk-width-4-5 list-item-transcription" v-html="record.transcription"></div>
                    <div class="uk-width-1-6 list-item-thumbnail"><img :src="'/images/thumb-' + record.img1" /></div>
                    <div class="uk-width-1-6 list-item-button"><div class="card-button-container"><button type="button" v-on:click='readCard(record._id)'>Lire la carte</button></div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Dispatcher from '../../dispatcher/dispatcher';

/* eslint-disable indent */
export default {
    name: 'CardList',
    props: { filteriser: Object },
    watch: {
    },
    mounted() {
        // console.log('CardList mounted()');
        Dispatcher.on("dataReady", () => {
            // console.log("CardList dataReady");
            this.filteredList = this.$store.state.filteriser.filteredList;
        });

        Dispatcher.on("filterChange", () => {
            // console.log("CardList filterChange");
            this.filteredList = this.$store.state.filteriser.filteredList;
        });

        if (this.$store.state.filteriser) {
            this.filteredList = this.$store.state.filteriser.filteredList;
        }
    },
    methods: {
        debug(id) {
            // console.log(id);
            this.inspectID = id;
        },
        date(dateString) {
            const parts = dateString.split('-');
            const year = parts[0];
            const month = parts[1];
            const day = parts[2];
            // const monthString = this.monthStrings[parseInt(month, 10) - 1];

            // return `${parseInt(day, 10)} ${monthString} ${year}`;
            return `${day} / ${month} / ${year}`;
        },
        readCard(id){
            this.$router.push(`/carte/${id}`);
        },
    },
    data: () => ({
        inspectID: undefined,
        monthStrings: [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre"

        ],
        filteredList: [],
    }),
    components: {},
};
</script>

<style lang="scss">
@keyframes grow {
  from {height: 4em;}
  to {height: 16em;}
}

@keyframes collapse {
  from {height: 16em;}
  to {height: 4em;}
}

.title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 3.8rem;
}

.filter-table {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media screen and (max-width: 640px){
        .superfluous {
            display: none;
        }
    }


    .list-item {
        height: 4em;
        border-bottom: solid #cccccc 1px;
        animation-name: collapse;
        animation-duration: 0.25s;
        &.inspecting {
            height: 16em;
            animation-name: grow;
            animation-duration: 0.25s;

            .list-item-details {
                padding: 1em;
                overflow: hidden;
                .list-item-transcription{
                    overflow: hidden;
                    font-family: transcriptFont;
                    margin-right: 1em;
                    padding-right: 1rem;
                    @media screen and (max-width: 640px){
                        padding-right: 0.5rem;
                    }
                    border-right: solid #cccccc 1px;
                }

                .list-item-thumbnail{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 640px){
                        display: none;
                    }
                }

                @media screen and (max-width: 640px){
                    margin: 0em;
                }
            }
        }
    }
}
.uk-table{
    tr{
        position: relative;
        .row-overlay{
            position: absolute;
            display: block;
            top:0px;
            left:0px;
            width: 100%;
            height: 100%;
        }
    }
}

.card-button-container {
    margin: 1rem;
    position: relative;
    height: 3em;
    margin-top: 0.5em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    button {
        width: 100%;
        height: 100%;
        display: inline-block;
        border: none;
        border-radius: 4px;
        padding: 1rem 2rem;
        margin: 0;
        text-decoration: none;
        background: var(--primary-color);
        color: #ffffff;
        font-size: 1rem;
        cursor: pointer;
        text-align: center;
        transition: background 250ms ease-in-out, 
                    transform 150ms ease;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:hover{
            background: var(--primary-accent-color);
            color: rgb(120, 120, 120);
        }

        &:active{
            background: var(--primary-selected-color);
            color: rgb(89, 89, 89);
        }

        @media screen and (max-width: 640px){
            padding: 0.25rem 0.5rem;
        }
    }
}
</style>