import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Galerie from '../views/Galerie.vue';
import Cartes from '../views/Cartes.vue';
import Carte from '../views/Carte.vue';
import Documents from '../views/Documents.vue';
import Apropos from '../views/Apropos.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Accueil",
    component: Home,
    meta: { title: 'Pierre Pagès | Accueil' },
  },
  {
    path: "/galerie",
    name: "Galerie",
    component: Galerie,
    meta: { title: 'Pierre Pagès | Galerie' },
  },
  {
    path: "/cartes",
    name: "Cartes Postales",
    component: Cartes,
    meta: { title: 'Pierre Pagès | Cartes Postales' },
  },
  {
    path: "/carte/:id",
    name: "Carte",
    component: Carte,
    props: (route) => ({ id: route.params.id }),
    meta: { title: 'Pierre Pagès | Carte' },
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
    meta: { title: 'Pierre Pagès | Documents' },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Apropos,
    meta: { title: 'Pierre Pagès | Contact' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = 'Pierre Pagès';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
