<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav  v-if="filteriser">
            <li class="uk-active filter-title">Filtres</li>
            <li class="uk-active">
                <div class="uk-parent">
                    <div class="chip" v-if="selectedPersonFrom" @click="clearFilter('personFrom')"><span class="chip-name">Exp: {{selectedPersonFrom}}</span><span class="chip-close" uk-icon="close"></span></div>
                    <div class="chip" v-if="selectedPersonTo" @click="clearFilter('personTo')"><span class="chip-name">Dest: {{selectedPersonTo}}</span><span class="chip-close" uk-icon="close"></span></div>
                    <div class="chip" v-if="selectedLocation" @click="clearFilter('location')"><span class="chip-name">Loc: {{selectedLocation}}</span><span class="chip-close" uk-icon="close"></span></div>
                    <div class="chip" v-if="selectedTag" @click="clearFilter('tag')"><span class="chip-name">Thème: {{selectedTag}}</span><span class="chip-close" uk-icon="close"></span></div>
                </div>
            </li>
            <!-- <li class="uk-parent">
                <a href="#">Date</a>
                <ul class="uk-nav-sub">
                <li>
                    <a href="#">Single Date</a>
                    <ul>
                    <li>Date</li>
                    <li><input ref="singleDate" range="" type="date" key="" v-on:change="dateDebug"/></li>
                    </ul>
                </li>
                <li>
                    <a href="#">Date range</a>
                    <ul>
                    <li>from</li>
                    <li><input ref="rangeDateStart" range="" type="date" key="" v-on:change="dateDebug"/></li>
                    <li>to</li>
                    <li><input ref="rangeDateEnd" range="" type="date" key="" v-on:change="dateDebug"/></li>
                    </ul>
                </li>
                </ul>
            </li> -->
            <li class="uk-parent">
                <a class="filter-subtitle" href="#">Personnes</a>
                <ul class="uk-nav-sub">
                    <li class="filter-subsubtitle">Expéditeur</li>
                    <li>
                        <input ref="authorsInput" list="peopleAuthors" name="author" @change="authorsChange" />
                        <datalist id="peopleAuthors">
                            <option :value="person" v-bind:key="person" v-for="person in filteriser.authors" />
                        </datalist>
                    </li>
                    <li class="filter-subsubtitle">Destinataire</li>
                    <li>
                        <input ref="recipientsInput" list="peopleRecipients" name="recipient" @change="recipientsChange" />
                        <datalist id="peopleRecipients">
                            <option :value="person" v-bind:key="person" v-for="person in filteriser.recipients" />
                        </datalist>
                    </li>
                </ul>
            </li>
            <li class="uk-parent">
                <a class="filter-subtitle" href="#">Lieux</a>
                <ul class="uk-nav-sub">
                <li class="filter-subsubtitle">Lieu</li>
                <li>
                    <input ref="locationInput" list="locations" name="location" @change="locationsChange" />
                    <datalist id="locations">
                        <option :value="location" v-bind:key="location" v-for="location in filteriser.locations" />
                    </datalist>
                </li>
                </ul>
            </li>
            <li class="uk-parent">
                <a class="filter-subtitle" href="#">Thèmes</a>
                <ul class="uk-nav-sub">
                <li class="filter-subsubtitle">Thème</li>
                <li>
                    <input ref="tagsInput" list="tags" name="tag" @change="tagsChange" />
                    <datalist id="tags">
                        <option :value="tag" v-bind:key="tag" v-for="tag in filteriser.tags" />
                    </datalist>
                </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import Dispatcher from '../../dispatcher/dispatcher';

export default {
  name: 'CardFilters',
  props: { filteriser: Object },
  watch: {
    filteriser: {
      handler(newFilteriser) {
        if (newFilteriser) {
          this.selectedPersonFrom = newFilteriser.getFilter("personFrom");
          this.selectedPersonTo = newFilteriser.getFilter("personTo");
          this.selectedLocation = newFilteriser.getFilter("location");
          this.selectedTag = newFilteriser.getFilter("tag");
        }
      },
    },
  },
  mounted() {
    
    // Dispatcher.on("dataReady", () => {
    // });
    // console.log("CardFilters mounted()");
    // console.log(this.$refs.singleDate);
    // console.log(this.$refs.rangeDateStart);
    // console.log(this.$refs.rangeDateEnd);
    // this.$refs.singleDate.defaultValue = "1914-01-01";
    // this.$refs.rangeDateStart.defaultValue = "1914-01-01";
    // this.$refs.rangeDateEnd.defaultValue = "1920-01-01";
    if (this.filteriser) {
      this.selectedPersonFrom = this.filteriser.getFilter("personFrom");
      this.selectedPersonTo = this.filteriser.getFilter("personTo");
      this.selectedLocation = this.filteriser.getFilter("location");
      this.selectedTag = this.filteriser.getFilter("tag");
    }
  },
  data: () => ({
    selectedPersonTo: undefined,
    selectedPersonFrom: undefined,
    selectedLocation: undefined,
    selectedTag: undefined,
  }),
  methods: {
    clearFilter(type) {
      switch (type) {
        case "personFrom":
          this.selectedPersonFrom = undefined;
          this.$refs.authorsInput.value = "";
          break;
        case "personTo":
          this.selectedPersonTo = undefined;
            this.$refs.recipientsInput.value = "";
          break;
        case "location":
          this.selectedLocation = undefined;
            this.$refs.locationInput.value = "";
          break;
        case "tag":
          this.selectedTag = undefined;
            this.$refs.tagsInput.value = "";
          break;
        default:
          break;
      }
      this.filteriser.clearFilter(type);
    },
    authorsChange(event) {
    //   console.log(event);
    //   console.log("authorsChange");
      // this.filteriser.clearFilters();
      this.filteriser.clearFilter("personFrom");
      this.filteriser.addFilter("personFrom", event.target.value);
      this.selectedPersonFrom = this.filteriser.getFilter("personFrom");
      // this.$refs.recipientInput.value = "";
      // this.$refs.locationInput.value = "";
      // this.$refs.tagsInput.value = "";
    },
    recipientsChange(event) {
    //   console.log(event);
    //   console.log("recipientsChange");
      // this.filteriser.clearFilters();
      this.filteriser.clearFilter("personTo");
      this.filteriser.addFilter("personTo", event.target.value);
      this.selectedPersonTo = this.filteriser.getFilter("personTo");
      // this.$refs.authorsInput.value = "";
      // this.$refs.locationInput.value = "";
      // this.$refs.tagsInput.value = "";
    },
    locationsChange(event) {
    //   console.log(event);
    //   console.log("locationsChange");
      // this.filteriser.clearFilters();
      this.filteriser.clearFilter("location");
      this.filteriser.addFilter("location", event.target.value);
      this.selectedLocation = this.filteriser.getFilter("location");
      // this.$refs.authorsInput.value = "";
      // this.$refs.recipientInput.value = "";
      // this.$refs.tagsInput.value = "";
    },
    tagsChange(event) {
    //   console.log(event);
    //   console.log("tagsChange");
      // this.filteriser.clearFilters();
      this.filteriser.clearFilter("tag");
      this.filteriser.addFilter("tag", event.target.value);
      this.selectedTag = this.filteriser.getFilter("tag");
      // this.$refs.authorsInput.value = "";
      // this.$refs.recipientInput.value = "";
      // this.$refs.locationInput.value = "";
    },
  },
  components: {},
};
</script>

<style lang="scss">
.filter-title{
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
    margin-top: 0.5em;
}

.filter-subtitle{
    margin-top: 1em;

    font-size: 1.25em;
    // bold text
    font-weight: bold;
}
.filter-subsubtitle{
    margin: 0.5em 0;

    // bold text
    // font-weight: bold;
}

.chip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    min-height: 30px;
    font-size: 1em;
    border-radius: 15px;
    background-color: #f1f1f1;

    margin-bottom: 1em;

    .chip-name {
        margin: 1em 0;
        font-weight: bold;
    }

    .chip-close {
        height: 25px;
        width: 25px;
        
        min-width: 25px;
        
    }

    &:hover {
        background-color: #e0e0e0;
    }
}
</style>