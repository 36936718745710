<template>
  <div class="galerie">
    <div id="image-scroller-container" ref='galleryArea'>
      <keep-alive>
        <ImageSlider />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ImageSlider from '../components/ImageSlider/ImageSlider.vue';
// @ is an alias to /src

export default {
  name: 'Galerie',
  data: () => ({
  }),
  mounted() {
    // console.log("Galerie mounted()")
    this.$refs.galleryArea.classList.add('show-gallery');
  },
  beforeDestroy() {
    // console.log("Galerie beforeDestroy()");
    this.$refs.galleryArea.classList.remove('show-gallery');
  },
  components: { ImageSlider },
};
</script>

<style lang="scss">
#image-scroller-container{
  position: relative;
  height: 100%;
  width: 100%;

  @keyframes gallery-show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.show-gallery {
    animation-name: gallery-show;
    animation-duration: 1s;
  }
}

.galerie {
  position: relative;
  height: calc(100% - (var(--nav-bar-height) + (2 * var(--nav-bar-vert-margin)) + 1px));
  width: 100%;
}
</style>
