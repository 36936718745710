<template>
    <div class="pp-footer">
        <strong>Copyright &copy; Louis Bennette ( 2023 - {{ new Date().getFullYear() }} ) All rights reserved.</strong>
    </div>
</template>

<script>
export default {
  name: 'PPFooter',
};
</script>

<style scoped>
  .pp-footer {
    /*background-color: #f8f9fa;*/

    margin-top: 1em;
    color: #495057;
    padding: 0.5rem;
    text-align: center;

    font-size: 0.75em;
  }
</style>
