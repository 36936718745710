<template>
  <div class="apropos uk-container">
    <h2>Contact</h2>
    <p>Si vous avez des questions sur notre site ou si vous avez des informations à partager, n'hésitez pas à nous contacter en utilisant le formulaire ci-dessous.</p>
    <div class="contact-form">
      <form
        action="https://formspree.io/f/xyyrolda"
        method="POST"
      >
          <label>
              Votre addresse e-mail:
              <input type="email" name="email">
          </label>
          <label>
              Votre message:
              <textarea name="message"></textarea>
          </label>
          <!-- your other form fields go here -->
          <button type="submit">Envoyer</button>
      </form>
    </div>
    <br/>
    <br/>
    <br/>
    
    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin" uk-grid>
        <div>
            <div class="uk-card-body">
                <h3 class="uk-card-title">Guy Catrix</h3>
                <p class="subheading"><i>Petit-fils de Pierre Pagès</i></p>
                <p>Propriétaire des cartes, conseiller et correcteur.</p>
                <!-- <p><i>Petit fils de Pierre Pagès.</i></p> -->
            </div>
        </div>
    </div>

    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin" uk-grid>
        <div>
            <div class="uk-card-body">
                <h3 class="uk-card-title">Catherine Catrix</h3>
                <p class="subheading"><i>Arrière-petite-fille de Pierre Pagès</i></p>
                <p>Conception et création de l’ouvrage. Archivage, numérisation et transcription des cartes et des documents.</p>
                <!-- <p><i>Fille the Guy Catrix</i></p> -->
            </div>
        </div>
    </div>
    
    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin" uk-grid>
        <div>
            <div class="uk-card-body">
                <h3 class="uk-card-title">Louis Bennette</h3>
                <p class="subheading"><i>Arrière-arrière-petit-fils de Pierre Pagès</i></p>
                <p>Conception et programation du site internet ainsi que du logiciel de transcription des données utilisé pour numériser les cartes postales.</p>
            </div>
        </div>
    </div>
    <p-p-footer></p-p-footer>
  </div>
</template>

<script>
import PPFooter from '../components/PPFooter.vue';

export default {
  name: 'Carnet',
  components: {
    PPFooter
  }
};
</script>

<style lang="scss">

.uk-card-title{
  margin-bottom: 0.1em;
}

.subheading {
  margin-top: 0.1em;
}

.apropos {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: left;

  .contact-form {

    /* Basic form styling */
    form {
      display: flex; /* Arrange elements horizontally */
      flex-direction: column; /* Stack elements vertically */
      border: 1px solid #ddd; /* Add a thin border */
      padding: 20px; /* Add padding for spacing */
      margin: 0 auto; /* Center the form horizontally */
      width: fit-content; /* Allow form to adjust to content size */
      min-width: 50%; /* Allow form to adjust to content size */
    }

    /* Label styling */
    label {
      display: block; /* Make labels appear on separate lines */
      margin-bottom: 5px; /* Add space between labels and inputs */
      font-weight: bold; /* Make labels stand out */
    }

    /* Input field styling */
    input[type="email"],
    textarea {
      width: 100%; /* Make inputs take up full width */
      padding: 10px; /* Add padding for better user experience */
      border: 1px solid #ccc; /* Add a border to inputs */
      border-radius: 4px; /* Add slightly rounded corners */
      font-size: 16px; /* Set font size for readability */
      box-sizing: border-box; /* Ensure padding doesn't affect width */
    }

    /* Textarea specific styling (optional) */
    textarea {
      height: 150px; /* Set a minimum height for the message field */
      resize: vertical; /* Allow users to resize the message field vertically */
    }

    /* Button styling */
    button[type="submit"] {
      background-color: var(--primary-color); /* Green color for the button */
      color: white; /* White text for the button */
      padding: 10px 20px; /* Add padding for a comfortable button size */
      border: none; /* Remove default button border */
      border-radius: 4px; /* Add slightly rounded corners */
      cursor: pointer; /* Indicate clickable behavior */
      margin-top: 10px; /* Add space above the button */
      font-size: 16px; /* Set font size for readability */
    }

    /* Button hover effect (optional) */
    button[type="submit"]:hover {
      color: rgb(120, 120, 120);
      background-color: var(--primary-accent-color); /* Darker green on hover */
    }
  }

}
</style>
