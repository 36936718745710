<template>
    <div>
        <h1>Chansons</h1>
        <h3>Ma Chrysantème</h3>
        <img src="/images/663b1609-9787-430c-9fdc-516861fd7b3cPS.jpg" />
        <img src="/images/663b1609-9787-430c-9fdc-516861fd7b3cMS.jpg" />

        <h3>Le Rémouleur</h3>
        <img src="/images/4ab2eeb2-04ab-4718-ada2-fe31919fe0c1PS.jpg" />
        <img src="/images/4ab2eeb2-04ab-4718-ada2-fe31919fe0c1MS.jpg" />

        <h3>Le Légionaire</h3>
        <img src="/images/38e18ad9-63e8-462f-a127-0753d4216c43PS.jpg" />
        <img src="/images/ce717bf6-482a-479e-a2e9-10446f5c6a25PS.jpg" />

        <h3>Fleurs des tranchées</h3>
        <img src="/images/38e18ad9-63e8-462f-a127-0753d4216c43PS.jpg" />
        <img src="/images/38e18ad9-63e8-462f-a127-0753d4216c43MS.jpg" />
    </div>
</template>

<script>
export default {
  name: 'Chansons',
};
</script>

<style lang="scss">

</style>
