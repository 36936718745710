<!-- eslint-disable no-underscore-dangle -->
<template>
  <img class="colourisation-image" src="pp-full-colours-edit.png" />
</template>

<script>

export default {
  name: '',
  components: { },

  beforeDestroy() {
  },
  mounted() {
    
  },
  data: () => ({
  }),
  methods: { 
  },
};
</script>

<style lang="scss" scoped>


.colourisation-image {
  position: relative;
  height: auto;
  width: 100%;
}
</style>
