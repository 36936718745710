<template>
    <div>
        <h1>Famille Pagès-Azéma</h1>
        <p><span class="name">Pierre Pagès (1888 – 1966)</span>: Pierre Henri Julien, auteur principal et instigateur de cette correspondance de guerre – Epoux de Marie Antoinette Azèma (16 juin 1908), père de Lucienne Pagès et grand père de Guy Catrix</p>
        <p><span class="name">Marie Pagès (1889 -1978)</span>: Marie Antoinette, dite Marinette, née Azéma,  épouse de Pierre Pagès, mère de Lucienne, sœur d’Elisabeth Azéma et de Joseph Azéma</p>
        <p><span class="name">Lucienne Pagès (1909 – 1933)</span>: Lucienne Augustine, fille de Pierre et Marinette Pagès, épouse de Joseph Catrix et mère de Guy Catrix</p>
        <p><span class="name">Augustin Louis Pagès (1848 – 1917)</span>: Père de Pierre Pagès et époux de Marie Angélique Reig</p>
        <p><span class="name">Marie Pagès (1858 - 1918)</span>: Marie Angélique, dite Maria, née Reig, mère de Pierre Pagès</p>
        <p><span class="name">Marie Pagès (1873 – 196?)</span>: Marie Catherine Pagès, sœur ainée des frères Pagès</p>
        <p><span class="name">Auguste Pagès  (1883 - 19?)</span>: frère de Pierre Pagès</p>
        <p><span class="name">Jean Pagès (1885 – 19 ?)</span>: frère de Pierre Pagès. Epoux de Maria et père de Pauline</p>
        <p><span class="name">Bonaventure Pagès (1889 – 1983)</span>: frère de Pierre Pagès – époux d’Elisabeth Azéma, sœur de Marinette</p>
        <p><span class="name">Elisabeth Azéma (1896 - 1982)</span>: Juliette Elisabeth, dite Babé, jeune sœur de Marinette – épouse de Bonaventure Pagès</p>
        <p><span class="name">Joseph Azéma (1892 - ?)</span>: Dit Pipette, frère cadet de Marinette</p>

        <br /><hr/><br />
        <div class="photo-container">
          <img class="photo" src="/images/b0dcdd21-19c0-4ae4-aea9-728176afc6d5PS.jpg" />
          <p><b>Pierre et Marinette Pagès avec leur fille Lucienne</b></p>
        </div>
        <!-- <hr />
        <img class="photo" src="/images/6b359288-0b80-460f-af6e-be3ce6710f11PS.jpg" />
        <p><b>Jean Pagès</b></p> -->
    </div>
</template>

<script>
export default {
  name: 'AuteursPrincip',
};
</script>

<style lang="scss" scoped>
.name {
  font-weight: bold;
}

.photo-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 640px) {
    img {
      width: 100%;
    }
  }
  .photo {
    margin: auto auto;
  }
}

</style>