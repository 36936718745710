import Vue from "vue";
import Vuex from "vuex";
import Filteriser from "../components/CardFilters/Filteriser";
import Dispatcher from "../dispatcher/dispatcher";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // imageCarouselController: undefined,
    websiteData: undefined,
    postcards: undefined,
    songs: undefined,
    postcardPictures: undefined,
  },
  mutations: {
    setWebsiteData(state, websiteData) {
      state.filteriser = new Filteriser(this, []);
      const types = new Set();
      websiteData.forEach((post) => {
        types.add(post.type);
      });
      state.websiteData = websiteData;
      state.postcards = websiteData.filter((post) => post.type === 'Postcard');
      state.songs = websiteData.filter((post) => post.type === 'Chanson');
      state.postcardPictures = [];
      state.postcards.forEach((post) => {
        state.postcardPictures.push({
          id: post._id,
          lowResUrl: `./images/thumb-${post.img1}`,
          highResUrl: `./images/${post.img1}`,
        });
      });
      // console.log('Website data:', websiteData);
      // console.log('Types:', types);
      // console.log('Postcards:', state.postcards);
      // console.log('Songs:', state.songs);
      // console.log('postcardPictures:', state.postcardPictures);
      // console.log('Filteriser:', state.filteriser);
      state.filteriser.setRecords(state.postcards);
      Dispatcher.dispatch('dataReady');
    },
  },
  actions: {
    async downloadWebsiteData({ commit }) {
      try {
        const response = await fetch('/data.json');
        const data = await response.json();
        commit('setWebsiteData', data);
      } catch (error) {
        console.error('Error downloading website data:', error);
        // Handle download error (optional: display error message)
      }
    },
  },
  modules: {},
});
