<template>
    <div class="image-slider">
      <div class="carousel-container " ref="carouselHolder"></div>
      <div class="display-container">
        <div class="display-canvas-container" ref="displayHolder"></div>
      </div>
      <div class="card-button-container"><button type="button" v-on:click='readCard()'>Lire la carte</button></div>
      <div class="nav-buttons">
        <div class="nav-button left-button" v-on:click='scrollImage(-1)'></div>
        <div class="nav-spacer"></div>
        <div class="nav-button right-button" v-on:click='scrollImage(1)'></div>
      </div>
      <div class="loading" ref="loadingEl"></div>
    </div>
</template>

<script>
import ImageCarouselController from './ImageCarouselController';
import axios from 'axios';
import { mapState } from 'vuex';
import Dispatcher from '../../dispatcher/dispatcher';

export default {
  name: 'ImageSlider',
  mounted() {
    this.setupCarousell();
    Dispatcher.on('dataReady', () => {
      // console.log('ImageSlider dataReady');
      this.postcardPictures = this.$store.state.postcardPictures;
      if (this.postcardPictures.length > 0) {
        this.controller.setImages(this.postcardPictures);
      }
    });
    if (this.$store.state.postcardPictures) {
      this.postcardPictures = this.$store.state.postcardPictures;
      if (this.postcardPictures.length > 0) {
        this.controller.setImages(this.postcardPictures);
      }
    }
  },
  beforeDestroy() {
    // console.log("unmounted");
    this.controller.stop();
  },
  unmounted() {
  },
  data: () => ({
    controller: undefined,
    postcardPictures: undefined,
    activeImage: 0,
  }),
  components: {},
  methods: {
    setupCarousell() {
      if (!this.controller) {
        if (!this.$store.state) {
          this.$store.state = {};
        }
        if (!this.$store.state.imageCarouselController) {
          this.$store.state.imageCarouselController = new ImageCarouselController();
          // console.log("New Galery")
        }
        this.controller = this.$store.state.imageCarouselController;
        this.controller.LOADING_CALLBACK = this.imageLoading.bind(this);
        this.controller.LOADED_CALLBACK = this.imageLoaded.bind(this);
        this.controller.start();
        this.$refs.carouselHolder.appendChild(this.controller.WEBGL_CANVAS);
        this.$refs.displayHolder.appendChild(this.controller.DISPLAY_CANVAS);
      }
    },
    scrollImage(direction) {
      if (direction < 0) {
        this.controller.moveLeft();
      } else {
        this.controller.moveRight();
      }
    },
    readCard(){
      this.$router.push(`/carte/${this.$store.state.imageCarouselController.getActiveCardId()}`);
    },
    imageLoading() {
      if (this.$refs.loadingEl) {
        this.$refs.loadingEl.classList.add('active');
      }
    },
    imageLoaded() {
      if (this.$refs.loadingEl) {
        this.$refs.loadingEl.classList.remove('active');
      }
    },
  },
};
</script>

<style lang="scss">
.image-slider{
    position: relative;
    width:100%;
    height:100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .carousel-container{
      // position: relative;
      width:100%;
      height: 140px;
      // height:150px;
      .carousel-canvas {
        position: relative;
        width:100%;
        height:100%;

        // background: rgb(87,87,87);
        // background: linear-gradient(0deg, rgba(87,87,87,0) 0%, rgba(87,87,87,0.1) 80%, rgba(87,87,87,0.4) 100%);
      }
    }

    .display-container{
      position: relative;
      width:100%;
      // height:100%;
      // height: 400px;


      height: calc(100% - (150px + 3em));
      // height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .display-canvas-container{
        position: relative;
        width:100%;
        // height: calc(100% - (1em + 20px));
        height: 100%;
        .display-canvas {
          position: relative;
          width:100%;
          height: 100%;
        }
      }
    }

    .card-button-container {
      position: relative;
      height: 3em;
      margin: 0.5em 0;
      // width: 100%;
      display: flex;
      justify-content: center;

      button {
        width: 200px;
        display: inline-block;
        border: none;
        border-radius: 4px;
        padding: 1rem 2rem;
        margin: 0;
        text-decoration: none;
        background: var(--primary-color);
        color: #ffffff;
        font-size: 1rem;
        cursor: pointer;
        text-align: center;
        transition: background 250ms ease-in-out, 
                    transform 150ms ease;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:hover{
          background: var(--primary-accent-color);
          color: rgb(120, 120, 120);
        }

        &:active{
          background: var(--primary-selected-color);
          color: rgb(89, 89, 89);
        }
      }
    }

    
    .nav-buttons{
      position: absolute;
      width: 100%;
      height: 100%;
      top:  0px;
      left: 0px;
      transform: translate(0, -0%);
      pointer-events: none;
      display: flex;
      justify-content: space-between;
      .nav-button{
        pointer-events: auto;
        height: 100%;
        width: 150px;
        &.left-button:hover{
          background: linear-gradient(270deg, rgba(128, 128, 128, 0) 0%, rgba(128, 128, 128, 0.5) 100%);
        }
        &.right-button:hover{
          background: linear-gradient(90deg, rgba(128, 128, 128, 0) 0%, rgba(128, 128, 128, 0.5) 100%);
        }
      }

      @media screen and (max-width: 640px) {
        .nav-button{
          width: 70px;
        }
      }
    }

    .loading{
      display: none;
      &.active {
        display: block;

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0);
      }
    }
}
</style>
