import Dispatcher from "../../dispatcher/dispatcher";

export default class Filteriser {
  #LIST = [];

  #FILTERED_LIST = [];

  #FILTERS = {};

  #authors = new Set();

  #recipients = new Set();

  #locations = new Set();

  #tags = new Set();

  #types = new Set();

  constructor(store, list) {
    this.STORE = store;
    this.#LIST = list;
    this.#FILTERED_LIST = [...this.#LIST];

    this.dateIsRange = false;
    this.fdate = "";

    // this.addFilter("type", "Postcard");
  }

  setRecords(list) {
    this.#LIST = list.filter((item) => item.type === "Postcard");

    const authors = new Set();
    const recipients = new Set();
    const locations = new Set();
    const tags = new Set();
    const types = new Set();

    this.#LIST.forEach((item) => {
      if (item.personFrom !== null && item.personFrom !== undefined && item.personFrom.length > 0) {
        authors.add(item.personFrom);
      }
      if (item.personTo !== null && item.personTo !== undefined && item.personTo.length > 0) {
        recipients.add(item.personTo);
      }
      if (item.locFrom !== null && item.locFrom !== undefined && item.locFrom.length > 0) {
        locations.add(item.locFrom);
      }
      if (item.locTo !== null && item.locTo !== undefined && item.locTo.length > 0) {
        locations.add(item.locTo);
      }
      if (item.tags) {
        item.tags.forEach((tag) => {
          if (tag !== null && tag !== undefined && tag.length > 0) {
            tags.add(tag);
          }
        });
      }
      if (item.type !== null && item.type !== undefined && item.type.length > 0) {
        types.add(item.type);
      }
    });

    const locComp = (a, b) => {
      return a.localeCompare(b);
    };

    this.#authors = Array.from(authors).sort(locComp);
    this.#recipients = Array.from(recipients).sort(locComp);
    this.#locations = Array.from(locations).sort(locComp);
    this.#tags = Array.from(tags).sort(locComp);
    this.#types = Array.from(types).sort(locComp);

    // console.log(this.#authors);
    // console.log(this.#recipients);
    // console.log(this.#locations);
    // console.log(this.#tags);
    // console.log(this.#types);

    this.#FILTERED_LIST = [...this.#LIST];
    // console.log("setRecords");
    // console.log(this.#FILTERED_LIST);
    Dispatcher.dispatch("filterChange");
  }

  clearFilter(fieldKey) {
    this.#FILTERS[fieldKey] = undefined;
    delete this.#FILTERS[fieldKey];
    Dispatcher.dispatch("filterChange");
  }

  clearFilters() {
    this.#FILTERS = {};
    this.#FILTERED_LIST = [...this.#LIST];
    Dispatcher.dispatch("filterChange");
  }

  addFilter(fieldKey, data) {
    this.#FILTERS[fieldKey] = data;
    Dispatcher.dispatch("filterChange");

    // console.log(fieldKey, data);
  }

  getFilters() {
    return this.#FILTERS;
  }

  getFilter(fieldKey) {
    return this.#FILTERS[fieldKey];
  }

  get authors() {
    return this.#authors;
  }

  get recipients() {
    return this.#recipients;
  }

  get locations() {
    return this.#locations;
  }

  get tags() {
    return this.#tags;
  }

  get types() {
    return this.#types;
  }

  get filteredList() {
    this.#FILTERED_LIST = [...this.#LIST];
    if (this.#FILTERS.personFrom) {
      this.#FILTERED_LIST = this.#FILTERED_LIST.filter(
        (item) => item.personFrom === this.#FILTERS.personFrom
      );
    }

    if (this.#FILTERS.personTo) {
      this.#FILTERED_LIST = this.#FILTERED_LIST.filter(
        (item) => item.personTo === this.#FILTERS.personTo
      );
    }

    if (this.#FILTERS.location) {
      this.#FILTERED_LIST = this.#FILTERED_LIST.filter(
        (item) => 
          item.locFrom === this.#FILTERS.location || 
          item.locTo === this.#FILTERS.location
      );
    }

    if (this.#FILTERS.tag) {
      this.#FILTERED_LIST = this.#FILTERED_LIST.filter(
        (item) => item.tags.includes(this.#FILTERS.tag)
      );
    }

    // Always sort list by date.
    this.#FILTERED_LIST = this.#FILTERED_LIST.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });

    return this.#FILTERED_LIST;
  }
}
